import { IDomainProfile } from '@/interfaces';
import { DomainsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setDomains(state: DomainsState, payload: IDomainProfile[]) {
        state.domains = payload;
    },
    setDomain(state: DomainsState, payload: IDomainProfile) {
        const domains = state.domains.filter(
            (domain: IDomainProfile) => domain.id !== payload.id);
        domains.push(payload);
        state.domains = domains;
    },
    removeDomain(state: DomainsState, payload: IDomainProfile) {
        const groups = state.domains.filter((domain: IDomainProfile) => domain.id !== payload.id);
        // companies.push(payload);
        state.domains = groups;
    },
};

const { commit } = getStoreAccessors<DomainsState, State>('');

export const commitSetDomains = commit(mutations.setDomains);
export const commitSetDomain = commit(mutations.setDomain);
export const commitRemoveDomain= commit(mutations.removeDomain);

