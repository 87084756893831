import { IBatchProfile } from '@/interfaces';
import { BatchesState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setBatches(state: BatchesState, payload: IBatchProfile[]) {
        state.batches = payload;
    },
    setBatch(state: BatchesState, payload: IBatchProfile) {
        const batches = state.batches.filter(
            (batch: IBatchProfile) => batch.id !== payload.id);
        batches.push(payload);
        state.batches = batches;
    },
    removeBatch(state: BatchesState, payload: IBatchProfile) {
        const batches = state.batches.filter((batch: IBatchProfile) => batch.id !== payload.id);
        // companies.push(payload);
        state.batches = batches;
    },
};

const { commit } = getStoreAccessors<BatchesState, State>('');

export const commitSetBatch = commit(mutations.setBatch);
export const commitRemoveBatch = commit(mutations.removeBatch);
export const commitSetBatches = commit(mutations.setBatches);
