import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate,
  ICompanyProfile, ICompanyProfileUpdate, ICompanyProfileCreate,
  IBatchProfile, IBatchProfileUpdate, IBatchProfileCreate,
  IUnitProfile, IUnitProfileUpdate, IUnitProfileCreate,IQueryGroupProfile,
  IGroupProfile, IGroupProfileUpdate, IGroupProfileCreate,
  IDomainProfile, IDomainProfileUpdate, IDomainProfileCreate,
  ITicketProfile, ITicketProfileUpdate, ITicketProfileCreate,
  IResultProfile, IResultProfileUpdate, IResultProfileCreate,
  IMemberProfile, IMemberProfileUpdate, IMemberProfileCreate,
 } from './interfaces';

function authHeaders(token: string, params:any=null) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: params,
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getCompanies(token: string) {
    return axios.get<ICompanyProfile[]>(`${apiUrl}/api/v1/companies/`, authHeaders(token));
  },
  async updateCompany(token: string, companyId: number, data: ICompanyProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/companies/${companyId}`, data, authHeaders(token));
  },
  async createCompany(token: string, data: ICompanyProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/companies/`, data, authHeaders(token));
  },
  async removeCompany(token: string, companyId: number) {
    return axios.delete(`${apiUrl}/api/v1/companies/${companyId}`, authHeaders(token));
  },
  async getBatches(token: string) {
    return axios.get<IBatchProfile[]>(`${apiUrl}/api/v1/batches/`, authHeaders(token));
  },
  async updateBatch(token: string, batchId: number, data: IBatchProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/batches/${batchId}`, data, authHeaders(token));
  },
  async createBatch(token: string, data: IBatchProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/batches/`, data, authHeaders(token));
  },
  async removeBatch(token: string, batchId: number) {
    return axios.delete(`${apiUrl}/api/v1/batches/${batchId}`, authHeaders(token));
  },
  async getUnits(token: string) {
    return axios.get<IUnitProfile[]>(`${apiUrl}/api/v1/units/`, authHeaders(token));
  },
  async updateUnit(token: string, unitId: number, data: IUnitProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/units/${unitId}`, data, authHeaders(token));
  },
  async createUnit(token: string, data: IUnitProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/units/`, data, authHeaders(token));
  },
  async removeUnit(token: string, unitId: number) {
    return axios.delete(`${apiUrl}/api/v1/units/${unitId}`, authHeaders(token));
  },
  async getGroups(token: string) {
    return axios.get<IGroupProfile[]>(`${apiUrl}/api/v1/groups/`, authHeaders(token));
  },
  async getGroupsOfUnit(unitId:number) {
    return axios.get<IQueryGroupProfile[]>(`${apiUrl}/api/v1/units/${unitId}/groups`);
  },
  async updateGroup(token: string, groupId: number, data: IGroupProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/groups/${groupId}`, data, authHeaders(token));
  },
  async createGroup(token: string, data: IGroupProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/groups/`, data, authHeaders(token));
  },
  async removeGroup(token: string, groupId: number) {
    return axios.delete(`${apiUrl}/api/v1/groups/${groupId}`, authHeaders(token));
  },
  async getDomains(token: string) {
    return axios.get<IDomainProfile[]>(`${apiUrl}/api/v1/domains/`, authHeaders(token));
  },
  async updateDomain(token: string, domainId: number, data: IDomainProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/domains/${domainId}`, data, authHeaders(token));
  },
  async createDomain(token: string, data: IDomainProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/domains/`, data, authHeaders(token));
  },
  async removeDomain(token: string, domainId: number) {
    return axios.delete(`${apiUrl}/api/v1/domains/${domainId}`, authHeaders(token));
  },
  async getTickets(token: string, params:any) {
    return axios.get<ITicketProfile[]>(`${apiUrl}/api/v1/tickets/`, authHeaders(token, params));
  },
  async updateTicket(token: string, ticketId: number, data: ITicketProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/tickets/${ticketId}`, data, authHeaders(token));
  },
  async createTicket(token: string, data: ITicketProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/tickets/`, data, authHeaders(token));
  },
  async removeTicket(token: string, domainId: number) {
    return axios.delete(`${apiUrl}/api/v1/tickets/${domainId}`, authHeaders(token));
  },
  async getResults(token: string, params:any) {
    return axios.get<IResultProfile[]>(`${apiUrl}/api/v1/results/`, authHeaders(token, params));
  },
  async queryResult(params:any) {
    return axios.get<IResultProfile>(`${apiUrl}/api/v1/results/wxquery`, authHeaders('', params));
  },
  async updateResult(token: string, resultId: number, data: IResultProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/results/${resultId}`, data, authHeaders(token));
  },
  async uploadResult(token: string, payload: FileList) {
    let formData = new FormData()
    formData.append('file', payload[0]);
    
    // let headers = authHeaders(token);
    // headers.headers[Content-Type'] = 'multipart/form-data' 

    return axios.post(`${apiUrl}/api/v1/results/uploadResults`, formData, authHeaders(token));
  },
  async createResult(token: string, data: IResultProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/results/`, data, authHeaders(token));
  },
  async importResults(token: string, data: IResultProfileCreate) {
    return axios.post<IResultProfile[]>(`${apiUrl}/api/v1/results/import`, data, authHeaders(token));
  },
  async removeResult(token: string, domainId: number) {
    return axios.delete(`${apiUrl}/api/v1/results/${domainId}`, authHeaders(token));
  },
  async getMembers(token: string) {
    return axios.get<IMemberProfile[]>(`${apiUrl}/api/v1/members/`, authHeaders(token));
  },
  async updateMember(token: string, memberId: number, data: IMemberProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/members/${memberId}`, data, authHeaders(token));
  },
  async createMember(token: string, data: IMemberProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/members/`, data, authHeaders(token));
  },
  async importMembers(token: string, data: IMemberProfileCreate) {
    return axios.post<IMemberProfile[]>(`${apiUrl}/api/v1/members/import`, data, authHeaders(token));
  },
  async removeMember(token: string, domainId: number) {
    return axios.delete(`${apiUrl}/api/v1/members/${domainId}`, authHeaders(token));
  },
};
