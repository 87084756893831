import { IResultProfile } from '@/interfaces';
import { ResultsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setResults(state: ResultsState, payload: IResultProfile[]) {
        state.results = payload;
    },
    setResult(state: ResultsState, payload: IResultProfile) {
        const results = state.results.filter(
            (result: IResultProfile) => result.id !== payload.id);
        results.push(payload);
        state.results = results;
    },
    removeResult(state: ResultsState, payload: IResultProfile) {
        const results = state.results.filter((result: IResultProfile) => result.id !== payload.id);
        // companies.push(payload);
        state.results = results;
    },
};

const { commit } = getStoreAccessors<ResultsState, State>('');

export const commitSetResults = commit(mutations.setResults);
export const commitSetResult = commit(mutations.setResult);
export const commitRemoveResult = commit(mutations.removeResult);

