import { MembersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminMembers: (state: MembersState) => state.members,
    adminMember: (state: MembersState) => (memberId: number) => {
        const filteredMembers = state.members.filter((member) => member.id === memberId);
        if (filteredMembers.length > 0) {
            return { ...filteredMembers[0] };
        }
    },
};

const { read } = getStoreAccessors<MembersState, State>('');

export const readAdminMember = read(getters.adminMember);
export const readAdminMembers = read(getters.adminMembers);
