import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IDomainProfileCreate, IDomainProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { DomainsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetDomains, commitSetDomain, commitRemoveDomain } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import i18n from '@/locale/index';

type MainContext = ActionContext<DomainsState, State>;

export const actions = {
    async actionGetDomains(context: MainContext) {
        try {
            const response = await api.getDomains(context.rootState.main.token);
            if (response) {
                commitSetDomains(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateDomain(context: MainContext, payload: { id: number, domain: IDomainProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('DomainsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateDomain(context.rootState.main.token, payload.id, payload.domain),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetDomain(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('DomainsActions.succUpdatedDomain').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateDomain(context: MainContext, payload: IDomainProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('DomainsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createDomain(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetDomain(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('DomainsActions.succCreatedDomain').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRemoveDomain(context: MainContext, payload: number) {
        try {
            const removingNotification = {
                content: i18n.t('DomainsActions.infoRemoving').toString(), showProgress: true };
            commitAddNotification(context, removingNotification);
            const response = (await Promise.all([
                api.removeDomain(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveDomain(context, response.data);
            commitRemoveNotification(context, removingNotification);
            commitAddNotification(context, { content: i18n.t('DomainsActions.succRemovedDomain').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<DomainsState, State>('');

export const dispatchCreateDomain = dispatch(actions.actionCreateDomain);
export const dispatchGetDomains = dispatch(actions.actionGetDomains);
export const dispatchUpdateDomain = dispatch(actions.actionUpdateDomain);
export const dispatchRemoveDomain = dispatch(actions.actionRemoveDomain);
