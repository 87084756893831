import { UnitsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminUnits: (state: UnitsState) => state.units,
    adminUnit: (state: UnitsState) => (unitId: number) => {
        const filteredUnits = state.units.filter((unit) => unit.id === unitId);
        if (filteredUnits.length > 0) {
            return { ...filteredUnits[0] };
        }
    },
    adminShools: (state: UnitsState) => state.units,
};

const { read } = getStoreAccessors<UnitsState, State>('');

export const readAdminUnit = read(getters.adminUnit);
export const readAdminUnits = read(getters.adminUnits);
export const readSchools = read(getters.adminShools);

