import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { UnitsState } from './state';

const defaultState: UnitsState = {
  units: [],
};

export const unitsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
