import { GroupsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminGroups: (state: GroupsState) => state.groups,
    adminGroup: (state: GroupsState) => (groupId: number) => {
        const filteredGroups = state.groups.filter((group) => group.id === groupId);
        if (filteredGroups.length > 0) {
            return { ...filteredGroups[0] };
        }
    },
};

const { read } = getStoreAccessors<GroupsState, State>('');

export const readAdminGroup = read(getters.adminGroup);
export const readAdminGroups = read(getters.adminGroups);
