import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TicketsState } from './state';

const defaultState: TicketsState = {
  tickets: [],
};

export const ticketsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
