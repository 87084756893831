export default {
    mainActions: {
        succLogin: 'Logged in',
        succUpdateProfile: 'Profile successfully updated',
        succLogout: 'Logged out',
        infoSendingEmail: 'Sending password recovery email',
        succSendEmail: 'Password recovery email sent',
        errIncorrectUsername: 'Incorrect username',
        infoResttingPwd: 'Resetting password',
        succPwdReset: 'Password successfully reset',
        errResettingPwd: 'Error resetting password',
    },
    adminActions: {
        infoSaving: 'saving',
        succUpdatedUser: 'User successfully updated',
        succCreatedUser: 'User successfully created',
    },
    CompanyActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        succUpdatedCompany: 'Company successfully updated',
        succCreatedCompany: 'Company successfully created',
        succRemovedCompany: 'Company successfully removed',
    },
    BatchesActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        succUpdatedBatch: 'Batch successfully updated',
        succCreatedBatch: 'Batch successfully created',
        succRemovedBatch: 'Batch successfully removed',
    },
    UnitsActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        succUpdatedUnit: 'Unit successfully updated',
        succCreatedUnit: 'Unit successfully created',
        succRemovedUnit: 'Unit successfully removed',
    },
    GroupsActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        succUpdatedGroup: 'Group successfully updated',
        succCreatedGroup: 'Group successfully created',
        succRemovedGroup: 'Group successfully removed',
    },
    DomainsActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        succUpdatedDomain: 'Domain successfully updated',
        succCreatedDomain: 'Domain successfully created',
        succRemovedDomain: 'Domain successfully removed',
    },
    TicketsActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        succUpdatedTicket: 'Ticket successfully updated',
        succCreatedTicket: 'Ticket successfully created',
        succRemovedTicket: 'Ticket successfully removed',
    },
    ResultsActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        infoUploading: 'Uploading',
        succImportedResult: 'Result successfully imported',
        succUpdatedResult: 'Result successfully updated',
        succCreatedResult: 'Result successfully created',
        succRemovedResult: 'Result successfully removed',
        succUploadedResult: 'Result successfully uploaded',
    },
    MembersActions: {
        infoSaving: 'saving',
        infoRemoving: 'removing',
        succImportedResult: 'Member successfully imported',
        succUpdatedResult: 'Member successfully updated',
        succCreatedResult: 'Member successfully created',
        succRemovedResult: 'Member successfully removed',
    },
    'eye-screening':'eye-screening',
    cancel: 'cancel',
    finished: 'finished',
    waiting: 'waiting',
    school: 'school',
    other: 'other',
};
