import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IBatchProfileCreate, IBatchProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { BatchesState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetBatches, commitSetBatch, commitRemoveBatch } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import i18n from '@/locale/index';

type MainContext = ActionContext<BatchesState, State>;

export const actions = {
    async actionGetBatches(context: MainContext) {
        try {
            const response = await api.getBatches(context.rootState.main.token);
            if (response) {
                commitSetBatches(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateBatch(context: MainContext, payload: { id: number, batch: IBatchProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('BatchesActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateBatch(context.rootState.main.token, payload.id, payload.batch),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetBatch(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('BatchesActions.succUpdatedBatch').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateBatch(context: MainContext, payload: IBatchProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('BatchesActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createBatch(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetBatch(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('BatchesActions.succCreatedBatch').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRemoveBatch(context: MainContext, payload: number) {
        try {
            const removingNotification = {
                content: i18n.t('BatchesActions.infoRemoving').toString(), showProgress: true };
            commitAddNotification(context, removingNotification);
            const response = (await Promise.all([
                api.removeBatch(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveBatch(context, response.data);
            commitRemoveNotification(context, removingNotification);
            commitAddNotification(context, { content: i18n.t('BatchesActions.succRemovedBatch').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<BatchesState, State>('');

export const dispatchCreateBatch = dispatch(actions.actionCreateBatch);
export const dispatchGetBatches = dispatch(actions.actionGetBatches);
export const dispatchUpdateBatch = dispatch(actions.actionUpdateBatch);
export const dispatchRemoveBatch = dispatch(actions.actionRemoveBatch);
