import { TicketsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminTickets: (state: TicketsState) => state.tickets,
    adminTicket: (state: TicketsState) => (ticketId: number) => {
        const filteredTickets = state.tickets.filter((ticket) => ticket.id === ticketId);
        if (filteredTickets.length > 0) {
            return { ...filteredTickets[0] };
        }
    },
};

const { read } = getStoreAccessors<TicketsState, State>('');

export const readAdminTicket = read(getters.adminTicket);
export const readAdminTickets = read(getters.adminTickets);
