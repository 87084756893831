import { DomainsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminDomains: (state: DomainsState) => state.domains,
    adminDomain: (state: DomainsState) => (domainId: number) => {
        const filteredDomains = state.domains.filter((domain) => domain.id === domainId);
        if (filteredDomains.length > 0) {
            return { ...filteredDomains[0] };
        }
    },
};

const { read } = getStoreAccessors<DomainsState, State>('');

export const readAdminDomain = read(getters.adminDomain);
export const readAdminDomains = read(getters.adminDomains);
