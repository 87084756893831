import { ITicketProfile } from '@/interfaces';
import { TicketsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTickets(state: TicketsState, payload: ITicketProfile[]) {
        state.tickets = payload;
    },
    setTicket(state: TicketsState, payload: ITicketProfile) {
        const tickets = state.tickets.filter(
            (ticket: ITicketProfile) => ticket.id !== payload.id);
        tickets.push(payload);
        state.tickets = tickets;
    },
    removeTicket(state: TicketsState, payload: ITicketProfile) {
        const tickets = state.tickets.filter((ticket: ITicketProfile) => ticket.id !== payload.id);
        // companies.push(payload);
        state.tickets = tickets;
    },
};

const { commit } = getStoreAccessors<TicketsState, State>('');

export const commitSetTickets = commit(mutations.setTickets);
export const commitSetTicket = commit(mutations.setTicket);
export const commitRemoveTicket = commit(mutations.removeTicket);

