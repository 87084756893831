import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MembersState } from './state';

const defaultState: MembersState = {
  members: [],
};

export const membersModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
