import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { companyModule } from './company';
import { batchesModule } from './batches';
import { unitsModule } from './unit';
import { groupsModule } from './group';
import { domainsModule } from './domain';
import { membersModule } from './member';
import { ticketsModule } from './ticket';
import { resultsModule } from './result';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    company: companyModule,
    batches: batchesModule,
    units: unitsModule,
    groups: groupsModule,
    domains: domainsModule,
    members: membersModule,
    tickets: ticketsModule,
    results: resultsModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);
export default store;
