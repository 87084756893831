import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { DomainsState } from './state';

const defaultState: DomainsState = {
  domains: [],
};

export const domainsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
