import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IUnitProfileCreate, IUnitProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { UnitsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUnits, commitSetUnit, commitRemoveUnit } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import i18n from '@/locale/index';

type MainContext = ActionContext<UnitsState, State>;

export const actions = {
    async actionGetUnits(context: MainContext) {
        try {
            const response = await api.getUnits(context.rootState.main.token);
            if (response) {
                commitSetUnits(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUnit(context: MainContext, payload: { id: number, unit: IUnitProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('UnitsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUnit(context.rootState.main.token, payload.id, payload.unit),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUnit(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('UnitsActions.succUpdatedUnit').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateUnit(context: MainContext, payload: IUnitProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('UnitsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUnit(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUnit(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('UnitsActions.succCreatedUnit').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRemoveUnit(context: MainContext, payload: number) {
        try {
            const removingNotification = {
                content: i18n.t('UnitsActions.infoRemoving').toString(), showProgress: true };
            commitAddNotification(context, removingNotification);
            const response = (await Promise.all([
                api.removeUnit(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveUnit(context, response.data);
            commitRemoveNotification(context, removingNotification);
            commitAddNotification(context, { content: i18n.t('UnitsActions.succRemovedUnit').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<UnitsState, State>('');

export const dispatchGetUnits = dispatch(actions.actionGetUnits);
export const dispatchCreateUnit = dispatch(actions.actionCreateUnit);
export const dispatchUpdateUnit = dispatch(actions.actionUpdateUnit);
export const dispatchRemoveUnit = dispatch(actions.actionRemoveUnit);
