import { api } from '@/api';
import { ActionContext } from 'vuex';
import { ICompanyProfileCreate, ICompanyProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { CompanyState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetCompanies, commitSetCompany, commitRemoveCompany } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import i18n from '@/locale/index';

type MainContext = ActionContext<CompanyState, State>;

export const actions = {
    async actionGetCompanies(context: MainContext) {
        try {
            const response = await api.getCompanies(context.rootState.main.token);
            if (response) {
                commitSetCompanies(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateCompany(context: MainContext, payload: { id: number, company: ICompanyProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('CompanyActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCompany(context.rootState.main.token, payload.id, payload.company),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetCompany(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: i18n.t('CompanyActions.succUpdatedCompany').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCompany(context: MainContext, payload: ICompanyProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('CompanyActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createCompany(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetCompany(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: i18n.t('CompanyActions.succCreatedCompany').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRemoveCompany(context: MainContext, payload: number) {
        try {
            const removingNotification = {
                content: i18n.t('CompanyActions.infoRemoving').toString(), showProgress: true };
            commitAddNotification(context, removingNotification);
            const response = (await Promise.all([
                api.removeCompany(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveCompany(context, response.data);
            commitRemoveNotification(context, removingNotification);
            commitAddNotification(context, { content: i18n.t('CompanyActions.succRemovedCompany').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<CompanyState, State>('');

export const dispatchCreateCompany = dispatch(actions.actionCreateCompany);
export const dispatchGetCompanies = dispatch(actions.actionGetCompanies);
export const dispatchUpdateCompany = dispatch(actions.actionUpdateCompany);
export const dispatchRemoveCompany = dispatch(actions.actionRemoveCompany);
