import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IResultProfileCreate, IResultProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { ResultsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetResults, commitSetResult, commitRemoveResult } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import i18n from '@/locale/index';

type MainContext = ActionContext<ResultsState, State>;

export const actions = {
    async actionGetResults(context: MainContext, params:any) {
        try {
            const response = await api.getResults(context.rootState.main.token, params);
            if (response) {
                commitSetResults(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateResult(context: MainContext, payload: { id: number, result: IResultProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('ResultsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateResult(context.rootState.main.token, payload.id, payload.result),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetResult(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('ResultsActions.succUpdatedResult').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateResult(context: MainContext, payload: IResultProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('ResultsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createResult(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetResult(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('ResultsActions.succCreatedResult').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRemoveResult(context: MainContext, payload: number) {
        try {
            const removingNotification = {
                content: i18n.t('ResultsActions.infoRemoving').toString(), showProgress: true };
            commitAddNotification(context, removingNotification);
            const response = (await Promise.all([
                api.removeResult(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveResult(context, response.data);
            commitRemoveNotification(context, removingNotification);
            commitAddNotification(context, { content: i18n.t('ResultsActions.succRemovedResult').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUploadsResult(context: MainContext, payload: FileList) {
        try {
            const loadingNotification = { content: i18n.t('ResultsActions.infoUploading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.uploadResult(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            // commitSetResult(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('ResultsActions.succUploadedResult').toString(), color: 'success' });
            
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<ResultsState, State>('');

export const dispatchCreateResult = dispatch(actions.actionCreateResult);
export const dispatchGetResults = dispatch(actions.actionGetResults);
export const dispatchUpdateResult = dispatch(actions.actionUpdateResult);
export const dispatchRemoveResult = dispatch(actions.actionRemoveResult);
export const dispatchUploadResult = dispatch(actions.actionUploadsResult);

