import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IGroupProfileCreate, IGroupProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { GroupsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetGroups, commitSetGroup, commitRemoveGroup } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import i18n from '@/locale/index';

type MainContext = ActionContext<GroupsState, State>;

export const actions = {
    async actionGetGroups(context: MainContext) {
        try {
            const response = await api.getGroups(context.rootState.main.token);
            if (response) {
                commitSetGroups(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateGroup(context: MainContext, payload: { id: number, group: IGroupProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('GroupsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateGroup(context.rootState.main.token, payload.id, payload.group),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetGroup(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('GroupsActions.succUpdatedGroup').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateGroup(context: MainContext, payload: IGroupProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('GroupsActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createGroup(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetGroup(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('GroupsActions.succCreatedGroup').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRemoveGroup(context: MainContext, payload: number) {
        try {
            const removingNotification = {
                content: i18n.t('GroupsActions.infoRemoving').toString(), showProgress: true };
            commitAddNotification(context, removingNotification);
            const response = (await Promise.all([
                api.removeGroup(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveGroup(context, response.data);
            commitRemoveNotification(context, removingNotification);
            commitAddNotification(context, { content: i18n.t('GroupsActions.succRemovedGroup').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<GroupsState, State>('');

export const dispatchCreateGroup = dispatch(actions.actionCreateGroup);
export const dispatchGetGroups = dispatch(actions.actionGetGroups);
export const dispatchUpdateGroup = dispatch(actions.actionUpdateGroup);
export const dispatchRemoveGroup = dispatch(actions.actionRemoveGroup);
