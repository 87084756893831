import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { CompanyState } from './state';

const defaultState: CompanyState = {
  companies: [],
};

export const companyModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
