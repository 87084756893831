export default {
    mainActions: {
        succLogin: '登录成功!',
        succUpdateProfile: '更新个人信息成功!',
        succLogout: '退出登录成功!',
        infoSendingEmail: '正在发送密码重置邮件...',
        succSendEmail: '密码重置邮件已放送到注册邮箱，请注意查收.',
        errIncorrectUsername: '用户名错误.',
        infoResttingPwd: '重置密码',
        succPwdReset: '密码修改成功!',
        errResettingPwd: '密码设置失败!',
    },
    adminActions: {
        infoSaving: '保存中...',
        succUpdatedUser: '修改账号信息成功',
        succCreatedUser: '创建账号成功',
    },
    CompanyActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        succUpdatedCompany: '修改企业信息成功',
        succCreatedCompany: '创建企业信息成功',
        succRemovedCompany: '删除企业信息成功',
    },
    BatchesActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        succUpdatedBatch: '修改检查批次信息成功',
        succCreatedBatch: '创建检查批次成功',
        succRemovedBatch: '删除检查批次信息成功',
    },
    UnitsActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        succUpdatedUnit: '修改学校信息成功',
        succCreatedUnit: '创建学校成功',
        succRemovedUnit: '删除学校信息成功',
    },
    GroupsActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        succUpdatedGroup: '修改班级信息成功',
        succCreatedGroup: '创建班级成功',
        succRemovedGroup: '删除班级信息成功',
    },
    DomainsActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        succUpdatedDomain: '修改公众号接入配置成功',
        succCreatedDomain: '创建公众号接入配置成功',
        succRemovedDomain: '删除公众号接入配置成功',
    },
    TicketsActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        succUpdatedTicket: '更新订单成功',
        succCreatedTicket: '创建订单成功',
        succRemovedTicket: '删除订单成功',
    },
    ResultsActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        infoUploading: '正在上传',
        succImportedResult: '导入检查结果成功',
        succUpdatedResult: '更新检查结果成功',
        succCreatedResult: '增加筛查结果成功',
        succRemovedResult: '删除筛查结果成功',
        succUploadedResult: '导入检查结果成功',
    },
    MembersActions: {
        infoSaving: '保存中...',
        infoRemoving: '正在清除...',
        succImportedResult: '导入会员信息成功',
        succUpdatedResult: '更新会员信息成功',
        succCreatedResult: '增加会员信息成功',
        succRemovedResult: '删除会员信息成功',
    },

    'eye-screening' : '眼科筛查',
    cancel : '取消',
    finished : '已完成',
    waiting : '等待处理',
    school: '学校',
    other: '其他',
};
