import { IUnitProfile } from '@/interfaces';
import { UnitsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUnits(state: UnitsState, payload: IUnitProfile[]) {
        state.units = payload;
    },
    setUnit(state: UnitsState, payload: IUnitProfile) {
        const units = state.units.filter(
            (unit: IUnitProfile) => unit.id !== payload.id);
        units.push(payload);
        state.units = units;
    },
    removeUnit(state: UnitsState, payload: IUnitProfile) {
        const units = state.units.filter((unit: IUnitProfile) => unit.id !== payload.id);
        // companies.push(payload);
        state.units = units;
    },
};

const { commit } = getStoreAccessors<UnitsState, State>('');

export const commitSetUnit = commit(mutations.setUnit);
export const commitRemoveUnit = commit(mutations.removeUnit);
export const commitSetUnits = commit(mutations.setUnits);
