import { IGroupProfile } from '@/interfaces';
import { GroupsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setGroups(state: GroupsState, payload: IGroupProfile[]) {
        state.groups = payload;
    },
    setGroup(state: GroupsState, payload: IGroupProfile) {
        const groups = state.groups.filter(
            (group: IGroupProfile) => group.id !== payload.id);
        groups.push(payload);
        state.groups = groups;
    },
    removeGroup(state: GroupsState, payload: IGroupProfile) {
        const groups = state.groups.filter((group: IGroupProfile) => group.id !== payload.id);
        // companies.push(payload);
        state.groups = groups;
    },
};

const { commit } = getStoreAccessors<GroupsState, State>('');

export const commitSetGroups = commit(mutations.setGroups);
export const commitSetGroup = commit(mutations.setGroup);
export const commitRemoveGroup = commit(mutations.removeGroup);

