import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'ticket',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "ticket" */ './views/NewTicket.vue'),
        },
        {
          path: 'query',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "query" */ './views/NewQuery.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'companies/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-companies" */ './views/main/company/AdminCompanies.vue'),
                },
                {
                  path: 'companies/edit/:id',
                  name: 'main-admin-companies-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-companies-edit" */ './views/main/company/EditCompany.vue'),
                },
                {
                  path: 'companies/create',
                  name: 'main-admin-companies-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-companies-create" */ './views/main/company/CreateCompany.vue'),
                },
                {
                  path: 'batches/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-batches" */ './views/main/batches/AdminBatches.vue'),
                },
                {
                  path: 'batches/edit/:id',
                  name: 'main-admin-batches-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-batches-edit" */ './views/main/batches/EditBatch.vue'),
                },
                {
                  path: 'batches/create',
                  name: 'main-admin-batches-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-batches-create" */ './views/main/batches/CreateBatch.vue'),
                },
                {
                  path: 'units/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-units" */ './views/main/unit/AdminUnits.vue'),
                },
                {
                  path: 'units/edit/:id',
                  name: 'main-admin-units-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-units-edit" */ './views/main/unit/EditUnit.vue'),
                },
                {
                  path: 'units/create',
                  name: 'main-admin-units-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-units-create" */ './views/main/unit/CreateUnit.vue'),
                },
                {
                  path: 'groups/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-groups" */ './views/main/group/AdminGroups.vue'),
                },
                {
                  path: 'groups/edit/:id',
                  name: 'main-admin-groups-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-groups-edit" */ './views/main/group/EditGroup.vue'),
                },
                {
                  path: 'groups/create',
                  name: 'main-admin-groups-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-groups-create" */ './views/main/group/CreateGroup.vue'),
                },
                {
                  path: 'domains/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-domains" */ './views/main/domain/AdminDomains.vue'),
                },
                {
                  path: 'domains/edit/:id',
                  name: 'main-admin-domains-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-domains-edit" */ './views/main/domain/EditDomain.vue'),
                },
                {
                  path: 'domains/create',
                  name: 'main-admin-domains-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-domains-create" */ './views/main/domain/CreateDomain.vue'),
                },
                {
                  path: 'members/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-members" */ './views/main/member/AdminMembers.vue'),
                },
                {
                  path: 'members/edit/:id',
                  name: 'main-admin-members-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-members-edit" */ './views/main/member/EditMember.vue'),
                },
                {
                  path: 'members/create',
                  name: 'main-admin-members-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-members-create" */ './views/main/member/CreateMember.vue'),
                },
                {
                  path: 'tickets/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-tickets" */ './views/main/ticket/AdminTickets.vue'),
                },
                {
                  path: 'tickets/edit/:id',
                  name: 'main-admin-tickets-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-tickets-edit" */ './views/main/ticket/EditTicket.vue'),
                },
                {
                  path: 'tickets/create',
                  name: 'main-admin-tickets-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-tickets-create" */ './views/main/ticket/CreateTicket.vue'),
                },
                {
                  path: 'results',
                  component: () => import(
                    /* webpackChunkName: "main-admin-results" */ './views/main/result/AdminResults.vue'),
                },
                {
                  path: 'results/edit',
                  name: 'main-admin-results-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-results-edit" */ './views/main/result/EditResult.vue'),
                },
                {
                  path: 'results/create',
                  name: 'main-admin-results-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-results-create" */ './views/main/result/CreateResult.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});

// const originalPush = Router.prototype.push;

// Router.prototype.push = function push(location) {
//   try {
//     return originalPush.call(this, location)
//   }catch(err) {
//     ;
//   };
// };
