import { ResultsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminResults: (state: ResultsState) => state.results,
    adminResult: (state: ResultsState) => (resultId: number) => {
        const filteredResults = state.results.filter((result) => result.id === resultId);
        if (filteredResults.length > 0) {
            return { ...filteredResults[0] };
        }
    },
};

const { read } = getStoreAccessors<ResultsState, State>('');

export const readAdminResult = read(getters.adminResult);
export const readAdminResults = read(getters.adminResults);
