import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ResultsState } from './state';

const defaultState: ResultsState = {
  results: [],
};

export const resultsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
