import { CompanyState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminCompanies: (state: CompanyState) => state.companies,
    adminOneCompany: (state: CompanyState) => (companyId: number) => {
        const filteredCompanis = state.companies.filter((company) => company.id === companyId);
        if (filteredCompanis.length > 0) {
            return { ...filteredCompanis[0] };
        }
    },
};

const { read } = getStoreAccessors<CompanyState, State>('');

export const readAdminOneCompany = read(getters.adminOneCompany);
export const readAdminCompanies = read(getters.adminCompanies);
