import { IMemberProfile } from '@/interfaces';
import { MembersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setMembers(state: MembersState, payload: IMemberProfile[]) {
        state.members = payload;
    },
    setMember(state: MembersState, payload: IMemberProfile) {
        const members = state.members.filter(
            (member: IMemberProfile) => member.id !== payload.id);
        members.push(payload);
        state.members = members;
    },
    removeMember(state: MembersState, payload: IMemberProfile) {
        const members = state.members.filter((member: IMemberProfile) => member.id !== payload.id);
        // companies.push(payload);
        state.members = members;
    },
};

const { commit } = getStoreAccessors<MembersState, State>('');

export const commitSetMembers = commit(mutations.setMembers);
export const commitSetMember = commit(mutations.setMember);
export const commitRemoveMember = commit(mutations.removeMember);

