// import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { default as zhCnLocale } from './zh-Hans';
import { default as enUsLocale } from './en';

import Vuetify from 'vuetify';

import { default as zhHans } from 'vuetify/src/locale/zh-Hans';
import { default as enUs } from 'vuetify/src/locale/en';

Vue.use(VueI18n);

const navLang = navigator.language;
const localLang = (navLang === 'zh-CN' || navLang === 'en-US') ? navLang : false;

// zh-CN 默认中文
const lang = localLang || 'zh-CN';

// 多语言配置 vue-i18n 6.x+
// Vue.locale = () => { };

const messages = {
    zh: Object.assign(zhCnLocale, { $vuetify: zhHans}),
    en: Object.assign(enUsLocale, { svuetify: enUs}),
};

const i18n = new VueI18n({
    locale: lang,
    messages,
    silentTranslationWarn: true,
});


Vue.use(Vuetify, {
    iconfont: 'md',
    current: 'zh',
    lang: {
      t: (key, ...params) => i18n.t(key, params),
    },
  });

export default i18n;
