import { BatchesState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminBatches: (state: BatchesState) => state.batches,
    adminBatch: (state: BatchesState) => (batchId: number) => {
        const filteredBatches = state.batches.filter((batch) => batch.id === batchId);
        if (filteredBatches.length > 0) {
            return { ...filteredBatches[0] };
        }
    },
};

const { read } = getStoreAccessors<BatchesState, State>('');

export const readAdminBatch = read(getters.adminBatch);
export const readAdminBatches = read(getters.adminBatches);
