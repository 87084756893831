import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IMemberProfileCreate, IMemberProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { MembersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetMembers, commitSetMember, commitRemoveMember } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import i18n from '@/locale/index';

type MainContext = ActionContext<MembersState, State>;

export const actions = {
    async actionGetMembers(context: MainContext) {
        try {
            const response = await api.getMembers(context.rootState.main.token);
            if (response) {
                commitSetMembers(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateMember(context: MainContext, payload: { id: number, member: IMemberProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('MembersActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMember(context.rootState.main.token, payload.id, payload.member),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetMember(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('MembersActions.succUpdatedMember').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateMember(context: MainContext, payload: IMemberProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('MembersActions.infoSaving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createMember(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetMember(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('MembersActions.succCreatedMember').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRemoveMember(context: MainContext, payload: number) {
        try {
            const removingNotification = {
                content: i18n.t('MembersActions.infoRemoving').toString(), showProgress: true };
            commitAddNotification(context, removingNotification);
            const response = (await Promise.all([
                api.removeMember(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveMember(context, response.data);
            commitRemoveNotification(context, removingNotification);
            commitAddNotification(context, { content: i18n.t('MembersActions.succRemovedMember').toString(), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<MembersState, State>('');

export const dispatchCreateMember = dispatch(actions.actionCreateMember);
export const dispatchGetMembers = dispatch(actions.actionGetMembers);
export const dispatchUpdateMember = dispatch(actions.actionUpdateMember);
export const dispatchRemoveMember = dispatch(actions.actionRemoveMember);
