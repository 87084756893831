import { ICompanyProfile } from '@/interfaces';
import { CompanyState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setCompanies(state: CompanyState, payload: ICompanyProfile[]) {
        state.companies = payload;
    },
    setCompany(state: CompanyState, payload: ICompanyProfile) {
        const companies = state.companies.filter((company: ICompanyProfile) => company.id !== payload.id);
        companies.push(payload);
        state.companies = companies;
    },
    removeCompany(state: CompanyState, payload: ICompanyProfile) {
        const companies = state.companies.filter((company: ICompanyProfile) => company.id !== payload.id);
        // companies.push(payload);
        state.companies = companies;
    },
};

const { commit } = getStoreAccessors<CompanyState, State>('');

export const commitSetCompany = commit(mutations.setCompany);
export const commitRemoveCompany = commit(mutations.removeCompany);
export const commitSetCompanies = commit(mutations.setCompanies);
